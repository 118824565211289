
$--color-primary: #989600;
$--color-success: #3ba62b;
$--color-danger: #fa3200;


//$--color-text-regular: #565655;
//$--color-text-secondary: #b1b1b2;

//$button-text-color: #000000;
//$button-disabled-text-color: #656161;

//$--button-primary-font-color: $button-text-color;
//$--button-danger-font-color: $button-text-color;
//$--button-default-font-color: $button-text-color;
//$--button-disabled-font-color: $button-text-color;
//$--button-danger-font-color: $button-text-color;
//$--button-info-font-color: $button-text-color;


$--border-radius-base: 7px;
$--card-border-radius: 7px;
$--tag-border-radius: 7px;

$--editable-border: 1px dashed #b9b9b9;